<template>
  <v-card class="mb-5 rounded-xl">
    <v-toolbar color="secondary">
      <v-toolbar-title>
        <v-icon class="mr-2">mdi-hexagon-multiple</v-icon>
        Case {{ collection.collection_id }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="12" xl="6">
          <p>
            <v-icon class="mr-2">mdi-hospital</v-icon>
            {{ collection.client_name }}
          </p>
          <p>
            <v-icon class="mr-2">mdi-account</v-icon>
            {{ collection.patient_reference }}
          </p>
        </v-col>
        <v-col cols="12" xl="6" v-if="step">
          <v-stepper v-model="step" class="rounded-xl" flat>
            <v-stepper-header>
              <v-stepper-step step="1"> Pre-Op </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="2"> Immediate </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="3"> Definitive </v-stepper-step>
            </v-stepper-header>
            <v-stepper-content step="1" v-if="smallScreen">
              <h4>Pre-Op Stage</h4>
            </v-stepper-content>
            <v-stepper-content step="2" v-if="smallScreen">
              <h4>Immediate Stage</h4>
            </v-stepper-content>
            <v-stepper-content step="3" v-if="smallScreen">
              <h4>Definitive Stage</h4>
            </v-stepper-content>
          </v-stepper>
          <v-card
            color="success"
            class="rounded-xl mt-2"
            v-if="step < 3"
            @click="upgradeOrder()"
          >
            <v-container class="text-center">
              <h3>
                <v-icon class="mr-2">mdi-arrow-up-bold</v-icon>
                Upgrade Case Now
              </h3>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CaseHeader",
  computed: {
    ...mapGetters(["collection"]),
    smallScreen() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      );
    },
    step() {
      if (this.collection.order_stage === "pre_op") {
        return 1;
      } else if (this.collection.order_stage === "immediate") {
        return 2;
      } else if (this.collection.order_stage === "definitive") {
        return 3;
      }
      return null;
    },
  },
  methods: {
    upgradeOrder() {
      this.$router.push({
        name: "upgradeCaseDirect",
        params: { upgradeUid: this.collection.uid },
      });
    },
  },
};
</script>
