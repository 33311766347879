import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import HomePage from "@/components/HomePage";
import ClientRegistration from "@/components/ClientRegistration";
import PracticeSelector from "@/components/PracticeSelector";
import OrderForm from "@osteon-nexus-ordering/nexus-ordering";
import CaseOverview from "@/components/CaseOverview";
import CaseChat from "@/components/CaseChat";
import CaseOrderForms from "@/components/CaseOrderForms";
import CaseOrderFiles from "@/components/CaseOrderFiles";
import KnowledgeBase from "@/components/KnowledgeBase";
import AboutApp from "@/components/AboutApp";
import AppSettings from "@/components/AppSettings";
import LogOut from "@/components/shared/LogOut";
import "@osteon-nexus-connect/nexus-connect/dist/style.css";
import { nxcHomePage, nxcTermsPage } from "@osteon-nexus-connect/nexus-connect";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "homePage",
    component: HomePage,
  },
  {
    path: "/callback",
    name: "authCallback",
    component: HomePage,
  },
  {
    path: "/home/:target",
    name: "targetHomePage",
    props: true,
    component: HomePage,
  },
  {
    path: "/register",
    name: "clientRegistration",
    component: ClientRegistration,
  },
  {
    path: "/practices",
    name: "practiceSelector",
    component: PracticeSelector,
  },
  {
    path: "/order",
    name: "orderForm",
    props: { upgrade: false },
    component: OrderForm,
  },
  {
    path: "/upgrade",
    name: "upgradeCase",
    props: { upgrade: true },
    component: OrderForm,
  },
  {
    path: "/upgrade/:upgradeUid",
    name: "upgradeCaseDirect",
    props: true,
    component: OrderForm,
  },
  {
    path: "/order/:uid",
    name: "existingOrderForm",
    props: true,
    component: OrderForm,
  },
  {
    path: "/preview/:productUid",
    name: "orderFormPreview",
    props: true,
    component: OrderForm,
  },
  {
    path: "/case/:uid",
    name: "caseOverview",
    props: true,
    component: CaseOverview,
  },
  {
    path: "/chat/:uid",
    name: "caseChat",
    props: true,
    component: CaseChat,
  },
  {
    path: "/forms/:uid",
    name: "caseOrderForms",
    props: true,
    component: CaseOrderForms,
  },
  {
    path: "/scans/:uid",
    name: "caseOrderFiles",
    props: true,
    component: CaseOrderFiles,
  },
  {
    path: "/faqs",
    name: "knowledgeBase",
    component: KnowledgeBase,
  },
  {
    path: "/logout",
    name: "logOut",
    component: LogOut,
  },
  {
    path: "/about",
    name: "about",
    component: AboutApp,
  },
  {
    path: "/settings",
    name: "settings",
    component: AppSettings,
  },
  {
    path: "/nexus-connect",
    name: "nxcHomePage",
    component: nxcHomePage,
  },
  {
    path: "/nexus-connect-terms",
    name: "nxcTermsPage",
    component: nxcTermsPage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (from && from.name === "caseChat") {
    store.commit("SET_CHAT_HIGHLIGHT_UID", null);
  }
  if (
    from &&
    [
      "orderForm",
      "upgradeCase",
      "upgradeCaseDirect",
      "existingOrderForm",
    ].includes(from.name)
  ) {
    store.commit("SET_RELOAD_TRIGGER", true);
  }
  next();
});

export default router;
