export default {
  11: { fdi: "11", palmer: "1", universal: "8" },
  12: { fdi: "12", palmer: "2", universal: "7" },
  13: { fdi: "13", palmer: "3", universal: "6" },
  14: { fdi: "14", palmer: "4", universal: "5" },
  15: { fdi: "15", palmer: "5", universal: "4" },
  16: { fdi: "16", palmer: "6", universal: "3" },
  17: { fdi: "17", palmer: "7", universal: "2" },
  21: { fdi: "21", palmer: "1", universal: "9" },
  22: { fdi: "22", palmer: "2", universal: "10" },
  23: { fdi: "23", palmer: "3", universal: "11" },
  24: { fdi: "24", palmer: "4", universal: "12" },
  25: { fdi: "25", palmer: "5", universal: "13" },
  26: { fdi: "26", palmer: "6", universal: "14" },
  27: { fdi: "27", palmer: "7", universal: "15" },
  31: { fdi: "31", palmer: "1", universal: "24" },
  32: { fdi: "32", palmer: "2", universal: "23" },
  33: { fdi: "33", palmer: "3", universal: "22" },
  34: { fdi: "34", palmer: "4", universal: "21" },
  35: { fdi: "35", palmer: "5", universal: "20" },
  36: { fdi: "36", palmer: "6", universal: "19" },
  37: { fdi: "37", palmer: "7", universal: "18" },
  41: { fdi: "41", palmer: "1", universal: "25" },
  42: { fdi: "42", palmer: "2", universal: "26" },
  43: { fdi: "43", palmer: "3", universal: "27" },
  44: { fdi: "44", palmer: "4", universal: "28" },
  45: { fdi: "45", palmer: "5", universal: "29" },
  46: { fdi: "46", palmer: "6", universal: "30" },
  47: { fdi: "47", palmer: "7", universal: "31" },
};
