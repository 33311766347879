<template>
  <v-row v-if="currentClient">
    <v-col cols="12" xl="6">
      <v-card class="rounded-xl" @click="newOrder()" color="primary">
        <v-container class="text-center">
          <h2>
            <v-icon large class="mr-2">mdi-account-plus</v-icon>
            New Patient
          </h2>
        </v-container>
      </v-card>
    </v-col>
    <v-col cols="12" xl="6">
      <v-card class="rounded-xl" @click="upgradeOrder()" color="success">
        <v-container class="text-center">
          <h2>
            <v-icon large class="mr-2">mdi-account-arrow-up</v-icon>
            Existing Patient
          </h2>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NewOrder",
  computed: {
    ...mapGetters(["currentClient"]),
  },
  methods: {
    newOrder() {
      this.$router.push({ name: "orderForm" });
    },
    upgradeOrder() {
      this.$router.push({ name: "upgradeCase" });
    },
  },
};
</script>
