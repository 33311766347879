<template>
  <v-container class="fill-height pa-0">
    <v-row v-if="loading">
      <v-col class="text-center">
        <RGBSpinner />
        <p class="my-5">Loading...</p>
      </v-col>
    </v-row>
    <v-card
      height="100%"
      width="100%"
      color="transparent"
      outlined
      v-if="!loading && collection"
    >
      <CaseHeader />
      <ActionRequired v-if="openApprovals.length > 0" />
      <v-row>
        <v-col
          v-for="(dentate, index) in dentates"
          :key="index"
          col="12"
          md="6"
        >
          <CaseDetail :dentate="dentate" />
        </v-col>
      </v-row>
      <v-row v-if="collection.cases">
        <v-col cols="12" class="text-center">
          <small>
            * Finished prostheses will only continue to production after the
            final
            <strong>Try-In Approval</strong> or final
            <strong>Design Approval</strong> - dispatch times are only valid
            once <strong>Try-In Approval</strong> or final
            <strong>Design Approval</strong> is completed.
          </small>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import RGBSpinner from "@/components/shared/RGBSpinner";
import CaseHeader from "./CaseOverview/CaseHeader";
import ActionRequired from "./CaseOverview/ActionRequired";
import CaseDetail from "./CaseOverview/CaseDetail";

export default {
  name: "CaseOverview",
  props: ["uid"],
  data() {
    return {
      loading: true,
    };
  },
  components: {
    RGBSpinner,
    CaseHeader,
    ActionRequired,
    CaseDetail,
  },
  computed: {
    ...mapGetters(["collection", "openApprovals", "dentates"]),
  },
  methods: {
    ...mapActions(["setCollection"]),
    async fetchCase() {
      this.loading = true;
      this.$axios
        .get(`/case/${this.uid}/`)
        .then((response) => {
          this.loading = false;
          this.setCollection(response.data);
        })
        .catch(() => {
          this.loading = false;
          this.setCollection(null);
        });
    },
  },
  mounted() {
    this.fetchCase();
  },
};
</script>
