<template>
  <v-card
    v-if="collection"
    elevation="0"
    class="rounded-xl"
    height="100%"
    width="100%"
  >
    <span v-for="(item, index) in timeline" :key="index">
      <CaseMessage v-if="item.__type === 'message'" :message="item" />
      <ApprovalBubble v-if="item.__type === 'approval'" :approval="item" />
    </span>
    <div class="my-5 text-center" v-if="timeline.length === 0">
      <p>
        <v-icon large>mdi-chat-sleep</v-icon>
      </p>
      <p>No Messages</p>
    </div>
    <MessageCompose
      :collection-uid="collection.uid"
      @sent="delayScrollToBottom()"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ApprovalBubble from "@/components/shared/ApprovalBubble";
import CaseMessage from "@/components/shared/CaseMessage";
import MessageCompose from "@/components/shared/MessageCompose";

export default {
  name: "CaseChat",
  props: ["uid"],
  components: {
    CaseMessage,
    ApprovalBubble,
    MessageCompose,
  },
  watch: {
    timeline() {
      if (this.totalTimelineItems !== this.timeline.length) {
        this.totalTimelineItems = this.timeline.length;
        this.delayScrollToBottom();
      }
    },
  },
  data() {
    return {
      totalTimelineItems: 0,
      intervalID: null,
    };
  },
  computed: {
    ...mapGetters(["collection", "chatHighlightUID"]),
    timeline() {
      let items = [];
      if (
        this.collection &&
        this.collection.messages &&
        this.collection.messages.length > 0
      ) {
        items = items.concat(
          this.collection.messages.map((message) => {
            return {
              ...message,
              __type: "message",
            };
          })
        );
      }
      if (
        this.collection &&
        this.collection.approvals &&
        this.collection.approvals.length > 0
      ) {
        items = items.concat(
          this.collection.approvals.map((approval) => {
            return {
              ...approval,
              __type: "approval",
            };
          })
        );
      }
      items.sort((a, b) => a.created_at.localeCompare(b.created_at));
      return items;
    },
  },
  methods: {
    ...mapActions(["setCollection"]),
    delayScrollToBottom() {
      setTimeout(this.scrollToTarget, 200);
    },
    scrollToTarget() {
      if (this.chatHighlightUID) {
        const approval = document.getElementById("messagePointer");
        if (approval) {
          this.$smoothScroll({ scrollTo: approval, offset: -100 });
          return;
        }
      }
      window.scrollTo(0, document.documentElement.scrollHeight);
    },
    async fetchCase() {
      this.loading = true;
      this.$axios.get(`/case/${this.collection.uid}/`).then((response) => {
        this.loading = false;
        this.setCollection(response.data);
      });
    },
  },
  beforeMount() {
    if (!this.collection) {
      this.$router.push({ name: "caseOverview", params: { uid: this.uid } });
    }
  },
  mounted() {
    this.totalTimelineItems = this.timeline.length;
    this.delayScrollToBottom();
    this.intervalID = setInterval(() => this.fetchCase(), 1000);
  },
  beforeDestroy() {
    if (!this.intervalID) {
      return;
    }
    clearInterval(this.intervalID);
  },
};
</script>
