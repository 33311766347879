<template>
  <v-card width="100%" class="rounded-xl" v-if="currentClient">
    <v-toolbar color="secondary">
      <v-toolbar-title>
        <v-icon class="mr-2">mdi-pencil</v-icon>
        Incomplete Orders
      </v-toolbar-title>
      <v-spacer />
      <v-avatar
        size="40"
        color="light-green"
        :class="incompleteSubmissions.length > 0 ? 'pulsing-dark' : ''"
      >
        {{ incompleteSubmissions.length }}
      </v-avatar>
    </v-toolbar>
    <v-card-text>
      <v-card
        class="overflow-y-auto"
        flat
        :max-height="smallScreen ? 300 : 600"
      >
        <div class="text-center" v-if="loading">
          <v-progress-circular
            size="128"
            color="primary"
            indeterminate
            class="ma-10"
          />
        </div>
        <div v-if="!loading">
          <div
            class="ma-0 py-1"
            v-for="(submission, index) in incompleteSubmissions"
            :key="index"
          >
            <v-list rounded>
              <v-list-item
                style="background-color: #263238"
                @click="loadOrder(submission)"
              >
                <v-list-item-avatar size="52">
                  <v-avatar color="black" size="52" dark>
                    <v-img
                      v-if="!submission.product_image"
                      contain
                      max-height="200"
                      :src="require('@/assets/icon.png')"
                    />
                    <v-img
                      v-if="!!submission.product_image"
                      contain
                      max-height="200"
                      :src="submission.product_image"
                    />
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ submission.product_name }}
                  </v-list-item-title>
                  <v-list-item-content>
                    <v-row>
                      <v-col cols="12" xl="6" class="py-0">
                        <v-icon small>mdi-account</v-icon>
                        {{ submission.patient_reference }}
                      </v-col>
                      <v-col cols="12" xl="6" class="py-0">
                        <small
                          >Started
                          {{ submission.created_at | luxonRelative }}</small
                        >
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item-content>
                <v-list-item-icon @click.stop="" class="my-auto">
                  <v-btn icon @click="deleteSubmission(submission)">
                    <v-icon color="warning">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </div>
        </div>
      </v-card>
      <div
        v-if="!loading && incompleteSubmissions.length === 0"
        class="text-center my-5"
      >
        <v-icon>mdi-sleep</v-icon>
        <br />
        No orders found...
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "IncompleteSubmissions",
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    reloadTrigger(newValue, previousValue) {
      if (previousValue && !newValue) {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters(["currentClient", "incompleteSubmissions", "reloadTrigger"]),
    smallScreen() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      );
    },
  },
  methods: {
    ...mapActions(["triggerReload"]),
    loadOrder(submission) {
      this.$router.push({
        name: "existingOrderForm",
        params: { uid: submission.uid },
      });
    },
    deleteSubmission(submission) {
      this.loading = true;
      this.$axios
        .delete(
          `/ordering/submissions/${this.currentClient.uid}/${submission.uid}/`
        )
        .then(() => {
          this.triggerReload();
        });
    },
  },
};
</script>
