<template>
  <v-card width="100%" height="100%" color="transparent" v-if="collection">
    <v-row>
      <v-col cols="12" v-if="orderFiles.length > 0">
        <v-card width="100%" class="rounded-xl">
          <v-toolbar color="secondary">
            <v-toolbar-title>
              <v-icon>mdi-folder</v-icon>
              Scans
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-list rounded>
              <v-list-item
                v-for="(file, index) in orderFiles"
                :key="index"
                @click="viewFile(file)"
              >
                <v-list-item-icon>
                  <v-icon v-if="isViewable(file) === 'image'">
                    mdi-image-area
                  </v-icon>
                  <v-icon v-if="isViewable(file) === 'model'">
                    mdi-rotate-3d
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ cleanFilename(file) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <FileViewer v-model="file" type="order_file" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="orderFiles.length === 0" class="text-center">
        <v-card elevation="0" class="rounded-xl">
          <div class="pa-5">
            <p>
              <v-icon large>mdi-file-question</v-icon>
            </p>
            <p>No Scans Available</p>
            <p>
              <small>
                Please note, if scans were not submitted at the time of placing
                this order, they will not appear here.
              </small>
            </p>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import FileViewer from "@/components/shared/FileViewer";

export default {
  name: "CaseOrderFiles",
  props: ["uid"],
  components: {
    FileViewer,
  },
  data() {
    return {
      file: null,
    };
  },
  computed: {
    ...mapGetters(["collection"]),
    orderFiles() {
      if (
        this.collection &&
        this.collection.cases &&
        this.collection.cases.length > 0
      ) {
        return this.collection.cases
          .flatMap((kase) => kase.order_files)
          .filter((file) => !!this.isViewable(file));
      }
      return [];
    },
  },
  methods: {
    viewFile(file) {
      this.file = file.id;
    },
    isViewable(file) {
      const fileExt = this.cleanFilename(file).toLowerCase().split(".").pop();
      switch (fileExt) {
        case "jpg":
        case "jpeg":
        case "png":
          return "image";
        case "stl":
        case "ply":
          return "model";
        default:
          return false;
      }
    },
    cleanFilename(file) {
      return file.filename.slice(7);
    },
  },
  beforeMount() {
    if (!this.collection) {
      this.$router.push({ name: "caseOverview", params: { uid: this.uid } });
    }
  },
};
</script>
