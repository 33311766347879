<template>
  <div v-if="approval.approval_files.length > 0">
    <ul style="padding-left: 0; list-style-type: none">
      <li v-for="(file, index) in approval.approval_files" :key="index">
        <a @click="viewFile(file)">
          <h3>
            <v-icon>mdi-paperclip</v-icon>
            {{ file.filename.slice(7) }}
          </h3>
        </a>
      </li>
    </ul>
    <FileViewer v-model="file" type="approval_file" />
  </div>
</template>

<script>
import FileViewer from "@/components/shared/FileViewer";

export default {
  name: "ApprovalFiles",
  props: ["approval"],
  data() {
    return {
      file: null,
    };
  },
  components: {
    FileViewer,
  },
  methods: {
    viewFile(file) {
      this.file = file.id;
    },
  },
};
</script>
