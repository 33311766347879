<template>
  <v-footer app rounded="0" class="pb-0" inset color="transparent">
    <v-row>
      <v-col cols="12" v-if="loading" class="text-center">
        Sending...
        <v-progress-linear indeterminate class="my-3" color="primary" />
      </v-col>
      <v-col cols="12" v-if="!loading">
        <v-textarea
          hide-details
          class="mb-2 rounded-xl"
          v-model="message"
          placeholder="Message"
          background-color="white"
          light
          solo
          auto-grow
          rows="1"
        >
          <template v-slot:prepend>
            <v-file-input
              dark
              v-model="attachments"
              hide-input
              multiple
              class="prepend-buttons"
            />
          </template>
          <template v-slot:append>
            <v-btn small icon @click="send()">
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </template>
        </v-textarea>
        <v-chip
          small
          v-for="(attachment, index) in attachments"
          :key="index"
          close
          class="py-1 my-1 mr-2"
          @click:close="removeAttachment(attachment)"
        >
          <v-icon small>mdi-paperclip</v-icon>
          {{ attachment.name }}
        </v-chip>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "MessageCompose",
  props: ["collectionUid"],
  data() {
    return {
      loading: false,
      message: "",
      attachments: [],
    };
  },
  methods: {
    ...mapActions(["setChatHighlightUID"]),
    removeAttachment(attachment) {
      const index = this.attachments.indexOf(attachment);
      if (index < 0) {
        return;
      }
      this.attachments.splice(index, 1);
    },
    async send() {
      if (!this.message) {
        return;
      }
      if (this.message.length === 0) {
        return;
      }
      this.loading = true;
      const formData = new FormData();
      formData.append("note", this.message);
      formData.append("collection_uid", this.collectionUid);
      this.attachments.forEach((attachment) => {
        formData.append("attachments", attachment, attachment.name);
      });
      this.$axios
        .post(`/message/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.setChatHighlightUID(null);
          this.message = "";
          this.attachments = [];
          this.loading = false;
          this.$emit("sent", true);
        });
    },
  },
};
</script>

<style>
.prepend-buttons {
  display: inline !important;
  margin-top: 0px !important;
  margin-right: 12px !important;
  padding-top: 0px !important;
}

.prepend-buttons > .v-input__prepend-outer {
  display: inline !important;
  margin-top: 2px !important;
  margin-right: 0px !important;
  padding-top: 0px !important;
}
</style>
