<template>
  <v-card width="100%" height="100%" color="transparent" v-if="collection">
    <v-row>
      <v-col cols="12" v-for="(form, index) in orderForms" :key="index">
        <OrderForm :form="form" class="mb-5" />
      </v-col>
      <v-col cols="12" v-if="orderForms.length === 0" class="text-center">
        <v-card elevation="0" class="rounded-xl">
          <div class="pa-5">
            <p>
              <v-icon large>mdi-file-question</v-icon>
            </p>
            <p>No Order Forms Received</p>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import OrderForm from "./CaseOrderForms/OrderForm";

export default {
  name: "CaseOrderForms",
  props: ["uid"],
  components: {
    OrderForm,
  },
  computed: {
    ...mapGetters(["collection"]),
    orderForms() {
      if (this.collection && this.collection.order_forms) {
        return this.collection.order_forms;
      }
      return [];
    },
  },
  beforeMount() {
    if (!this.collection) {
      this.$router.push({ name: "caseOverview", params: { uid: this.uid } });
    }
  },
};
</script>
