<template>
  <div class="my-5">
    <div v-for="(approval, index) in openApprovals" :key="index">
      <ApprovalBubble :stand-alone="true" :approval="approval" />
    </div>
    <v-row v-if="noInformation">
      <v-col cols="12" class="text-center">
        <p>
          <v-icon>mdi-sleep</v-icon>
        </p>
        <p>
          <small>No additional information available</small>
        </p>
      </v-col>
    </v-row>
    <v-row v-if="showEstimatedDispatch">
      <v-col cols="6">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <small v-bind="attrs" v-on="on">
              Estimated Dispatch Date<sup>*</sup>
            </small>
          </template>
          Finished prostheses will only continue to production after the final
          <strong>Try-In Approval</strong> or final
          <strong>Design Approval</strong>.
          <br />
          Dispatch times are only valid once <strong>Try-In Approval</strong> or
          final <strong>Design Approval</strong> is completed.
        </v-tooltip>
      </v-col>
      <v-col cols="6">
        {{ kase.customer_due | luxon }}
      </v-col>
      <v-col
        cols="12"
        v-if="kase.product_class === 'Final Prosthesis'"
        class="text-center"
      >
        <p class="warning--text">
          <v-icon color="warning" small class="mr-2">mdi-alert</v-icon>
          3 Weeks After <strong>Final Approval</strong>
        </p>
      </v-col>
    </v-row>
    <div v-if="showDispatchInformation">
      <v-row v-if="dispatchInformation.dispatchedAt">
        <v-col cols="6">
          <small>Dispatch Date</small>
        </v-col>
        <v-col cols="6">
          {{ dispatchInformation.dispatchedAt | luxon }}
        </v-col>
      </v-row>
      <v-row v-if="dispatchInformation.courier">
        <v-col cols="6">
          <small>Courier</small>
        </v-col>
        <v-col cols="6">
          {{ dispatchInformation.courier }}
        </v-col>
      </v-row>
      <v-row v-if="dispatchInformation.trackingNumber">
        <v-col cols="6">
          <small>Tracking Reference</small>
        </v-col>
        <v-col cols="6">
          {{ dispatchInformation.trackingNumber }}
        </v-col>
      </v-row>
      <v-row v-if="dispatchInformation.shippingAddress">
        <v-col cols="6">
          <small>Shipping Address</small>
        </v-col>
        <v-col cols="6" style="white-space: pre-line">
          {{ dispatchInformation.shippingAddress }}
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapGetters } from "vuex";
import ApprovalBubble from "@/components/shared/ApprovalBubble";

export default {
  name: "ExpandedDetail",
  props: ["kase"],
  components: {
    ApprovalBubble,
  },
  computed: {
    ...mapGetters(["collection"]),
    noInformation() {
      if (
        !this.openApprovals.length > 0 &&
        !this.showEstimatedDispatch &&
        !this.showDispatchInformation
      ) {
        return true;
      }
      if (this.showDispatchInformation) {
        if (
          !this.dispatchInformation.dispatchedAt &&
          !this.dispatchInformation.courier &&
          !this.dispatchInformation.trackingNumber &&
          !this.dispatchInformation.shippingAddress
        ) {
          return true;
        }
      }
      return false;
    },
    showEstimatedDispatch() {
      if (
        this.kase.dispatched ||
        this.kase.cancelled ||
        this.openApprovals.length > 0
      ) {
        return false;
      }
      if (!this.kase.customer_due) {
        return false;
      }
      const dueTime = DateTime.fromISO(this.kase.customer_due);
      return dueTime > DateTime.now();
    },
    showDispatchInformation() {
      return this.kase.dispatched;
    },
    dispatchInformation() {
      const payload = {
        dispatchedAt: null,
        courier: null,
        trackingNumber: null,
        shippingAddress: null,
      };
      if (this.kase.dispatches.length > 0) {
        const latestDispatch = [...this.kase.dispatches].sort((a, b) =>
          b.dispatched_at.localeCompare(a.dispatched_at)
        )[0];
        payload.dispatchedAt = latestDispatch.dispatched_at;
        payload.courier = latestDispatch.courier;
        payload.trackingNumber = latestDispatch.tracking_reference;
        payload.shippingAddress = latestDispatch.shipping_address;
        return payload;
      }
      payload.dispatchedAt = this.kase.date_dispatched;
      payload.trackingNumber = this.kase.tracking_number;
      return payload;
    },
    openApprovals() {
      if (!this.collection) {
        return [];
      }
      return this.collection.approvals.filter((approval) => {
        if (this.kase.grouped_case_uids) {
          if (
            approval.case_uid !== this.kase.uid &&
            !this.kase.grouped_case_uids.includes(approval.case_uid)
          ) {
            return false;
          }
        } else {
          if (approval.case_uid !== this.kase.uid) {
            return false;
          }
        }
        return !approval.approved_at && !approval.cancelled_at;
      });
    },
  },
};
</script>
