<template>
  <v-card height="100%" width="100%" flat color="transparent" class="mt-2">
    <v-row>
      <v-col cols="12" md="4" lg="4" xl="4" align="center">
        <v-card class="rounded-xl">
          <v-toolbar color="secondary">
            <v-toolbar-title>
              <v-icon class="mr-2">mdi-tooth</v-icon>
              Numbering Convention
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <ToothConventionSelector />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" lg="8" xl="8">
        <v-card width="100%" class="rounded-xl">
          <v-toolbar color="secondary">
            <v-toolbar-title>
              <v-icon class="mr-2">mdi-card-account-details</v-icon>
              Authorized Users
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-list>
              <v-list-item
                v-for="(identity, index) in clientIdentifications"
                :key="index"
                two-line
              >
                <v-list-item-icon>
                  <v-icon v-if="identity.type === 'mobile'">
                    mdi-cellphone
                  </v-icon>
                  <v-icon v-if="identity.type === 'email'"> mdi-email </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ identity.identification }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ identity.type }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="triggerDelete(identity)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="success"
              @click="newIDModal = true"
              class="rounded-xl mr-2 mb-2"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              Add User
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-if="targetDelete"
      v-model="deleteDialog"
      :max-width="650"
      persistent
      class="rounded-xl"
    >
      <v-card class="rounded-xl">
        <v-toolbar flat color="error">
          <v-card-title>
            <v-icon class="mr-2"> mdi-delete </v-icon>
            Delete Identity
          </v-card-title>
          <v-spacer />
          <v-icon @click="deleteDialog = false"> mdi-close </v-icon>
        </v-toolbar>
        <v-card-text class="py-5">
          <h3>Are you sure you want to delete this identity?</h3>
          <br />
          <p>
            <v-icon v-if="targetDelete.type === 'mobile'" class="mr-2">
              mdi-cellphone
            </v-icon>
            <v-icon v-if="targetDelete.type === 'email'" class="mr-2">
              mdi-email
            </v-icon>
            {{ targetDelete.identification }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="deleteDialog = false"
            color="success"
            class="mr-2 rounded-xl mb-2"
          >
            <v-icon class="mr-2">mdi-cancel</v-icon>
            Cancel
          </v-btn>
          <v-btn
            @click="deleteIdentification"
            color="error"
            class="rounded-xl mb-2 mr-2"
          >
            <v-icon class="mr-2">mdi-delete</v-icon>
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="newIDModal"
      persistent
      :max-width="650"
      class="rounded-xl"
    >
      <v-card class="rounded-xl">
        <v-toolbar flat color="primary">
          <v-card-title>
            <v-icon class="mr-2"> mdi-account </v-icon>
            Add Identity
          </v-card-title>
          <v-spacer />
          <v-icon @click="newIDModal = false"> mdi-close </v-icon>
        </v-toolbar>
        <v-card-text class="py-5">
          <v-row v-if="!newIDType">
            <v-col cols="6" class="text-center">
              <v-card
                class="rounded-xl"
                color="secondary"
                @click="newIDType = 'mobile'"
              >
                <v-card-text class="text-center">
                  <h3 class="my-2">
                    <v-icon>mdi-cellphone</v-icon>
                  </h3>
                  <h3 class="my-2">Mobile Number</h3>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-card
                class="rounded-xl"
                color="secondary"
                @click="newIDType = 'email'"
              >
                <v-card-text class="text-center">
                  <h3 class="my-2">
                    <v-icon>mdi-email</v-icon>
                  </h3>
                  <h3 class="my-2">Email Address</h3>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-card
            v-if="newIDType === 'email'"
            class="my-5 rounded-xl"
            elevation="0"
          >
            <v-card-text>
              <v-text-field
                v-model="newID"
                outlined
                autofocus
                light
                background-color="white"
                rounded
                clearable
                placeholder="Email Address"
                type="email"
              />
            </v-card-text>
          </v-card>
          <v-card
            v-if="newIDType === 'mobile'"
            class="my-5"
            min-height="200"
            elevation="0"
          >
            <v-card-text>
              <VuePhoneNumberInput
                v-model="newID"
                :border-radius="24"
                default-country-code="AU"
                :preferred-countries="['AU', 'US']"
                size="lg"
                clearable
                autofocus
                dark
                @update="updateMobile"
              />
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="newIDModal = false"
            color="error"
            class="mr-2 mb-2 rounded-xl"
          >
            <v-icon class="mr-2">mdi-cancel</v-icon>
            Cancel
          </v-btn>
          <v-btn
            v-if="newIDType"
            @click="addIdentity"
            color="success"
            class="rounded-xl mr-2 mb-2"
          >
            <v-icon class="mr-2">mdi-plus</v-icon>
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ToothConventionSelector from "@/components/shared/ToothConventionSelector.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  name: "AppSettings",
  components: {
    ToothConventionSelector,
    VuePhoneNumberInput,
  },
  watch: {
    newIDModal(value) {
      if (value) {
        return;
      }
      (this.newIDType = null), (this.newID = ""), (this.phoneResults = null);
    },
    currentClient() {
      this.fetchClientIdentifications();
    },
  },
  data() {
    return {
      newIDModal: false,
      newIDType: null,
      newID: "",
      phoneResults: null,
      targetDelete: null,
      clientIdentifications: [],
    };
  },
  computed: {
    ...mapGetters(["currentClient"]),
    deleteDialog: {
      get() {
        return !!this.targetDelete;
      },
      set(value) {
        if (value) {
          return;
        }
        this.targetDelete = null;
      },
    },
  },
  methods: {
    triggerDelete(identity) {
      this.targetDelete = identity;
    },
    async deleteIdentification() {
      if (!this.targetDelete) {
        return;
      }
      this.$axios
        .delete(
          `/${this.currentClient.uid}/identifications/${this.targetDelete.uid}/`
        )
        .then(() => {
          this.$axios
            .get(`/${this.currentClient.uid}/identifications/`)
            .then((response) => {
              this.clientIdentifications = response.data;
            })
            .then(() => {
              this.targetDelete = null;
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    updateMobile(payload) {
      this.phoneResults = payload;
    },
    async addIdentity() {
      const identification = this.validateID();
      if (!identification) {
        return;
      }
      this.$axios
        .post(`/${this.currentClient.uid}/identifications/`, {
          id_string: identification,
        })
        .then(() => {
          this.$axios
            .get(`/${this.currentClient.uid}/identifications/`)
            .then((response) => {
              this.clientIdentifications = response.data;
            })
            .then(() => {
              this.newIDModal = false;
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    validateID() {
      if (this.newIDType === "email") {
        if (!this.newID || this.newID.length === 0) {
          return false;
        }
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.newID)) {
          return false;
        }
        return this.newID;
      } else if (this.newIDType === "mobile") {
        if (!this.phoneResults) {
          return false;
        }
        if (!this.phoneResults.isValid) {
          return false;
        }
        return this.phoneResults.e164;
      }
    },
    async fetchClientIdentifications() {
      if (!this.currentClient) {
        return;
      }
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`/${this.currentClient.uid}/identifications/`)
          .then((response) => {
            this.clientIdentifications = response.data;
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
  mounted() {
    this.fetchClientIdentifications();
  },
};
</script>
