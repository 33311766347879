<template>
  <span>
    <v-btn-toggle class="rounded-xl" v-model="toothConvention">
      <v-btn small value="fdi">FDI</v-btn>
      <v-btn small value="palmer">Pal.</v-btn>
      <v-btn small value="universal">Uni.</v-btn>
    </v-btn-toggle>
  </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ToothConventionSelector",
  computed: {
    ...mapGetters(["internationalToothConvention"]),
    toothConvention: {
      get() {
        return this.internationalToothConvention;
      },
      set(value) {
        this.setInternationalToothConvention(value);
        this.$cookies.set("internationalToothConvention", value);
      },
    },
  },
  methods: {
    ...mapActions(["setInternationalToothConvention"]),
  },
};
</script>
