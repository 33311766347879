<template>
  <v-dialog :max-width="800" persistent class="rounded-xl" v-model="modal">
    <v-card light class="rounded-xl">
      <v-toolbar dark color="primary">
        <v-card-title class="mx-auto text-center">
          <div class="text-h6 text-lg-h5">Welcome to the new Nexus Portal</div>
        </v-card-title>
      </v-toolbar>
      <v-card-text class="pa-5">
        <v-row>
          <v-col class="text-center">
            <h3>
              Designed to make case ordering and approval simpler then ever.
            </h3>
            <p class="pt-5 pb-0 black--text">Now also integrated with:</p>
            <v-img
              contain
              class="mx-auto"
              max-width="200"
              :src="require('@/assets/nexus-connect-logo.svg')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <iframe
              class="rounded-xl"
              :width="smallScreen ? '100%' : 560"
              height="315"
              :src="result"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <h3 class="black--text">Any questions?</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn @click="contactUs" color="primary" class="rounded-xl ma-2">
              <v-icon class="mr-2">mdi-email</v-icon>
              Contact Us
            </v-btn>
            <v-btn
              :to="{ name: 'knowledgeBase' }"
              color="primary"
              class="rounded-xl"
            >
              <v-icon class="mr-2">mdi-frequently-asked-questions</v-icon>
              Knowledge Base
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-checkbox label="Don't show this again" v-model="dontShowAgain" />
        <v-spacer />
        <v-btn color="success" class="rounded-xl mb-2" @click="hideModal"
          >Get Started</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "IntroModal",
  props: ["value"],

  data() {
    return {
      modal: false,
      youtubeURL: "https://youtu.be/yWKhXTYWVYY",
      result: "",
      dontShowAgain: false,
    };
  },

  watch: {
    value(value) {
      if (!value) {
        this.result = "";
        this.modal = false;
        return;
      }
      this.modal = true;
    },
  },

  computed: {
    smallScreen() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      );
    },
  },

  methods: {
    loadVideoURL() {
      const youtubeEmbedTemplate = "https://www.youtube.com/embed/";
      const url = this.youtubeURL.split(
        /(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/
      );
      const YId =
        undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];
      const topOfQueue = youtubeEmbedTemplate.concat(YId);
      this.result = topOfQueue;
    },

    contactUs() {
      window.open("https://www.nexusios.com/contact-us", "_blank");
    },

    hideModal() {
      if (this.dontShowAgain) this.$cookies.set("introWatched", "yes", "90d");

      this.$emit("input", false);
    },
  },

  mounted() {
    this.loadVideoURL();
  },
};
</script>
