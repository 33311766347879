<template>
  <v-card
    color="white"
    class="rounded-xl mt-5"
    v-if="currentClient"
    @click="openLink"
  >
    <v-card-text class="pb-0">
      <v-img
        class="mx-auto px-2"
        max-width="300"
        :src="require('@/assets/nexus-connect-logo.svg')"
      />
    </v-card-text>
    <v-card-subtitle class="text-center black--text">
      Scan Alignment Software
    </v-card-subtitle>
    <v-card-text class="text-center">
      <v-img
        class="mx-auto"
        :src="require('@/assets/nexus-connect-animation.gif')"
        max-width="300"
      />
      <v-btn text color="primary" class="mt-4">Validate Now</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NexusConnect",
  computed: {
    ...mapGetters(["currentClient"]),
    ...mapGetters("nxc", ["termsAccepted"]),
  },
  methods: {
    openLink() {
      if (this.termsAccepted) {
        this.$router.push({ name: "nxcHomePage" });
      } else {
        this.$router.push({ name: "nxcTermsPage" });
      }
    },
  },
};
</script>
