<template>
  <v-card height="100%" width="100%" flat color="#121212" class="mt-2">
    <v-card flat class="rounded-xl mb-5">
      <v-toolbar flat color="secondary">
        <v-toolbar-title>
          <v-icon class="mr-2">mdi-open-source-initiative</v-icon>
          Open Source Licenses
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-textarea
          rows="15"
          outlined
          readonly
          class="rounded-lg"
          :value="require('@/assets/licences/attribution.txt')"
        />
      </v-card-text>
    </v-card>
    <div class="text-center mt-5">
      <p>Created by Osteon's Software Development Team</p>
      <p>&copy; {{ currentYear }} Osteon Medical</p>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "AboutApp",
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
