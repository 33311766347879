<template>
  <div></div>
</template>

<script>
export default {
  name: "KnowledgeBase",
  mounted() {
    window.open("https://www.nexusios.com/knowledge-base/");
    this.$router.push({ name: "homePage" });
  },
};
</script>
