<template>
  <div v-if="message.attachments.length > 0">
    <ul style="padding-left: 0; list-style-type: none">
      <li v-for="(attachment, index) in message.attachments" :key="index">
        <ImagePreview
          :image="attachment"
          v-if="isPreviewable(attachment) === 'image'"
          @clicked="viewFile"
        />
        <a
          @click="viewFile(attachment)"
          v-if="isPreviewable(attachment) === false"
        >
          <h3>
            <v-icon>mdi-paperclip</v-icon>
            {{ cleanFilename(attachment) }}
          </h3>
        </a>
      </li>
    </ul>
    <FileViewer v-model="file" type="message_attachment" />
  </div>
</template>

<script>
import FileViewer from "@/components/shared/FileViewer";
import ImagePreview from "./MessageAttachments/ImagePreview";

export default {
  name: "MessageAttachments",
  props: ["message"],
  data() {
    return {
      file: null,
    };
  },
  components: {
    FileViewer,
    ImagePreview,
  },
  methods: {
    viewFile(file) {
      this.file = file.uid;
    },
    isPreviewable(attachment) {
      const fileExt = this.cleanFilename(attachment)
        .toLowerCase()
        .split(".")
        .pop();
      switch (fileExt) {
        case "jpg":
        case "jpeg":
        case "png":
          return "image";
        default:
          return false;
      }
    },
    cleanFilename(attachment) {
      return attachment.filename.slice(7);
    },
  },
};
</script>
