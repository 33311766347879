<template>
  <v-list rounded>
    <v-list-item @click="openCase(kase.uid)" style="background-color: #263238">
      <v-list-item-icon>
        <v-icon large>mdi-hexagon-multiple</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ kase.collection_id }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ kase.patient_reference }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-icon v-if="showOrderStage" class="my-auto">
        <v-chip x-small label outlined color="success">
          <span v-if="kase.order_stage === 'pre_op'">Pre-Op</span>
          <span v-if="kase.order_stage === 'immediate'">Immediate</span>
          <span v-if="kase.order_stage === 'definitive'">Definitive</span>
        </v-chip>
      </v-list-item-icon>
      <v-list-item-icon class="my-auto">
        <v-icon v-if="!loading">mdi-chevron-right</v-icon>
        <v-progress-circular v-if="loading" size="24" indeterminate />
      </v-list-item-icon>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "CaseCard",
  props: ["kase", "showOrderStage"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    openCase(caseUID) {
      this.$router.push({ name: "caseOverview", params: { uid: caseUID } });
    },
  },
};
</script>
