<template>
  <v-card width="100%" class="rounded-xl" id="upgradable">
    <v-toolbar flat color="success">
      <v-toolbar-title>
        <v-icon large>mdi-arrow-up-bold</v-icon>
        Cases Ready for Upgrade
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="extended = !extended">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-toolbar>
    <CaseSearch v-model="caseFilter" :extended="extended" color="success" />
    <v-card-text>
      <v-card class="overflow-y-auto" flat max-height="600">
        <div
          class="ma-0 py-1"
          v-for="(kase, index) in filteredUpgradableCases"
          :key="index"
        >
          <v-lazy>
            <CaseCard :kase="kase" :show-order-stage="true" />
          </v-lazy>
        </div>
      </v-card>
      <div v-if="filteredUpgradableCases.length === 0" class="text-center my-5">
        <v-icon>mdi-sleep</v-icon>
        <br />
        No cases found...
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import CaseCard from "@/components/shared/CaseCard";
import CaseSearch from "@/components/shared/CaseSearch";

export default {
  name: "UpgradableCases",
  components: {
    CaseCard,
    CaseSearch,
  },
  data() {
    return {
      caseFilter: "",
      extended: false,
    };
  },
  computed: {
    ...mapGetters(["upgradableCases"]),
    filteredUpgradableCases() {
      if (!this.caseFilter) {
        return this.upgradableCases;
      }
      return this.upgradableCases.filter((activeCase) => {
        return (
          `${activeCase.collection_id}`.includes(this.caseFilter) ||
          activeCase.patient_reference
            .toLowerCase()
            .includes(this.caseFilter.toLowerCase())
        );
      });
    },
  },
};
</script>
