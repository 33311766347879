<template>
  <v-card height="100%" width="100%" flat color="#121212" class="mt-2">
    <v-row>
      <v-col cols="12" md="4">
        <NewOrder class="mb-2" />
        <IncompleteSubmissions />
        <NexusConnect v-if="!smallScreen" />
      </v-col>
      <v-col cols="12" md="4">
        <PracticeOverview />
      </v-col>
      <v-col cols="12" md="4">
        <ApprovalRequiredCases v-if="view === 'approval'" />
        <UpgradableCases v-if="view === 'upgradable'" />
        <ActiveCases v-if="view === 'active'" />
        <ArchivedCases v-if="view === 'archived'" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import NewOrder from "./NewOrder";
import IncompleteSubmissions from "./IncompleteSubmissions";
import NexusConnect from "./NexusConnect";
import PracticeOverview from "./PracticeOverview";
import ApprovalRequiredCases from "./ApprovalRequiredCases";
import UpgradableCases from "./UpgradableCases";
import ActiveCases from "./ActiveCases";
import ArchivedCases from "./ArchivedCases";

export default {
  name: "HomePage",
  props: ["target"],
  components: {
    NewOrder,
    IncompleteSubmissions,
    NexusConnect,
    PracticeOverview,
    ApprovalRequiredCases,
    UpgradableCases,
    ActiveCases,
    ArchivedCases,
  },
  data() {
    return {
      viewState: null,
    };
  },
  watch: {
    target(value) {
      this.view = value;
      this.delayScrollToBottom();
    },
  },
  computed: {
    view: {
      get() {
        return this.viewState || this.target;
      },
      set(value) {
        this.viewState = value;
      },
    },
    smallScreen() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      );
    },
  },
  methods: {
    delayScrollToBottom() {
      setTimeout(this.scrollToTarget, 200);
    },
    scrollToTarget() {
      const target = document.getElementById(this.view);
      if (target) {
        this.$smoothScroll({ scrollTo: target });
        return;
      }
      window.scrollTo(0, document.documentElement.scrollHeight);
    },
  },
};
</script>
